import React, { useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ITCRevenueVSExpenses } from '../../api/interfaces'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

const TCRevenueVSExpenses = () => {
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1w')
	const InitializeData = async (timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_revenue_vs_expenses', true, timeframe)) as ITCRevenueVSExpenses[]
			console.log(chartData)
			const series1 = chartData.map((element: ITCRevenueVSExpenses) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.EXPENSES }
			})
			const series2 = chartData.map((element: ITCRevenueVSExpenses) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.REVENUE }
			})
			setDataSeries([
				{
					data: series1,
					strokeWidth: 2,
					name: 'expenses',
					type: 'bars',
					seriesGroup: '[rune]',
					color: 'red',
					unitSymbol: 'ᚱ'
				},
				{
					data: series2,
					strokeWidth: 2,
					name: 'revenue',
					type: 'bars',
					seriesGroup: '[rune]',
					color: 'green',
					unitSymbol: 'ᚱ'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Revenue vs Expenses"
				chartDesc="Breakdown of the THORChain revenue and expenses. 
				Revenue sources: network fees (currently 0.02 RUNE per transaction), node slashing (network earns RUNE when a node misbehaves), outbound fee (network transaction fee when sending swap outputs to different blockchains), liquidity fees. 
				Expense sources: impermanent protection (liquidity provider insurance), block rewards (liquidity provider and node incentives), gas reimbursement."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="c747462b-9b6f-4af4-9c7c-f0d73c8d59fb"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default TCRevenueVSExpenses
