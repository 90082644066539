import React, { useContext, useEffect, useState } from 'react'
import WideCard from '../components/WideCard.tsx/WideCard'
import { Row, Col } from 'react-bootstrap'
import ChartContent from '../components/Chart/ChartContent'
import { STable, SDashboard } from './sharedSectionStyles'
import { getBackendData } from '../api/api'
import { dateFormatter, nFormatter } from '../utils/numbers'
import { minimizeAddress, getViewblockAddressLink } from '../utils/address'
import { ITHORCHAINDashboard, ITokenHolder } from '../api/interfaces'
import { IChartConfig } from '../components/Chart/interface'
import LoadingChart from '../components/LoadingIcon/Loading'
import { getSaverAddressesConfig } from './saversSection/SaverAddresses'
import { getSaverDepthsUSDConfig } from './saversSection/SaverDepthUSD'
import { ThemeContext } from '../App'
import { getDeterministicRuneConfig } from './networkSection/DeterministicRune'
import { getLendingRuneBurntConfig } from './lendingSection/LendingRuneBurnt'
import { getTotalValueLockedConfig } from './networkSection/TVLocked'
import { getPctOfEarningsFromLiqFeesConfig } from './networkSection/PctOfEarningsFromLiqFees'
import { getSwapVolumeConfig } from './swapsSection/SwapVolume'
import { getSwapCountConfig } from './swapsSection/SwapCount'
import { getLPDepthDistributionConfig } from './liquidityPoolsSection/LPDepthDistribution'

const FeaturedDashboard = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dashboardData, setDashboardData] = useState<ITHORCHAINDashboard>()
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries2, setDataSeries2] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries3, setDataSeries3] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries4, setDataSeries4] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries5, setDataSeries5] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries6, setDataSeries6] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries7, setDataSeries7] = useState<IChartConfig | undefined>(undefined)
	const [dataSeries8, setDataSeries8] = useState<IChartConfig | undefined>(undefined)

	const InitializeData = async (color: string, inverseColor: string) => {
		try {
			setDashboardData(await getBackendData('thorchain', 'dashboard', false))
			setDataSeries(await getDeterministicRuneConfig(false, '1w'))
			setDataSeries2(await getLPDepthDistributionConfig(color, inverseColor, false, '1m'))
			setDataSeries3(await getSaverDepthsUSDConfig(color, inverseColor, false, '1m'))
			setDataSeries4(await getTotalValueLockedConfig(false, '1w'))
			setDataSeries5(await getPctOfEarningsFromLiqFeesConfig(false, '1w'))
			setDataSeries6(await getLendingRuneBurntConfig(color, inverseColor, false, '1d'))
			setDataSeries7(await getSwapCountConfig(false, '1m'))
			setDataSeries8(await getSwapVolumeConfig(color, inverseColor, false, '1m'))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		let inverseMainColor = theme === 'dark' ? 'black' : 'white'
		InitializeData(mainColor, inverseMainColor)
	}, [theme])
	if (
		dashboardData !== undefined &&
		dataSeries !== undefined &&
		dataSeries2 !== undefined &&
		dataSeries3 !== undefined &&
		dataSeries4 !== undefined &&
		dataSeries5 !== undefined &&
		dataSeries6 !== undefined &&
		dataSeries7 !== undefined &&
		dataSeries8 !== undefined
	) {
		return (
			<SDashboard>
				<Row>
					<Col>
						<WideCard
							cardTitle="Total Swap Volume"
							cardValue={`${nFormatter(dataSeries8.latestValue)} USD`}
							cardValueDate={`${dateFormatter(dataSeries8.latestValueDate)}`}
							link="/thorchain_swap_volume"
						/>
						<ChartContent dataSeries={dataSeries8.config} chartHeight="35vh" chartWidth="100%" chartTitle="Swap Volume" isMinimalChart={true} hasLogScale={false} />
					</Col>
				</Row>
				<br />
				<Row>
					<Col>
						<WideCard
							cardTitle="Total Pooled / Hard Cap [ᚱ]"
							cardValue={`${nFormatter(dashboardData.TOTAL_POOLED_RUNE)} ᚱ / ${nFormatter(dashboardData.HARD_CAP_RUNE)} ᚱ`}
							cardValueDate={`${dateFormatter(dataSeries.latestValueDate)}`}
						/>

						<ChartContent dataSeries={dataSeries.config} chartHeight="30vh" chartWidth="100%" chartTitle="Rune: Price [USD]" isMinimalChart={true} hasLogScale={false} />
					</Col>
					<Col>
						<WideCard cardTitle="Total Swap Count" cardValue={`${nFormatter(dataSeries7.latestValue)}`} cardValueDate={`${dateFormatter(dataSeries7.latestValueDate)}`} link="/thorchain_swap_count" />
						<ChartContent dataSeries={dataSeries7.config} chartHeight="35vh" chartWidth="50%" chartTitle="Swap Count" isMinimalChart={true} hasLogScale={false} />
					</Col>
				</Row>
				<br />
				<Row>
					<Col>
						<WideCard
							cardTitle="Liquidity in Pools"
							cardValue={`${nFormatter(dataSeries2.latestValue)} USD`}
							cardValueDate={`${dateFormatter(dataSeries2.latestValueDate)}`}
							link="/thorchain_lp_liquidity_distribution"
						/>
						<ChartContent dataSeries={dataSeries2.config} chartHeight="35vh" chartWidth="50%" chartTitle="Liquidity Distribution" isMinimalChart={true} hasLogScale={false} />
					</Col>
				</Row>
				<br />
				<Row>
					<Col>
						<WideCard
							cardTitle="Total Value Locked"
							cardValue={`${nFormatter(dataSeries4.latestValue)} USD`}
							cardValueDate={`${dateFormatter(dataSeries4.latestValueDate)}`}
							link="/thorchain_tv_locked"
						/>
						<ChartContent dataSeries={dataSeries4.config} chartHeight="35vh" chartWidth="50%" chartTitle="Total Value Locked" isMinimalChart={true} hasLogScale={false} />
					</Col>
					<Col>
						<WideCard
							cardTitle="Earning % From Fees 30d avg"
							cardValue={`${nFormatter(dataSeries5.latestValue)} %`}
							cardValueDate={`${dateFormatter(dataSeries5.latestValueDate)}`}
							link="/thorchain_pct_earnings_from_liq_fees"
						/>
						<ChartContent dataSeries={dataSeries5.config} chartHeight="35vh" chartWidth="100%" chartTitle="Earning % From Fees" isMinimalChart={true} hasLogScale={false} />
					</Col>
				</Row>
			</SDashboard>
		)
	} else {
		return <LoadingChart />
	}
}

export default FeaturedDashboard
